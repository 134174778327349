// 1. 创建一个新的axios实例
// 2. 请求拦截器，如果有token进行头部携带
// 3. 响应拦截器：1. 剥离无效数据  2. 处理token失效
// 4. 导出一个函数，调用当前的axsio实例发请求，返回值promise

import axios from 'axios';
import vue from "../main";

// 导出基准地址，原因：其他地方不是通过axios发请求的地方用上基准地址
export const baseURL = process.env.VUE_APP_Server
const instance = axios.create({
  // axios 的一些配置，baseURL  timeout
  baseURL,
  // timeout: 5000
})

instance.interceptors.request.use(config => {
  //console.log("baseURL:"+baseURL);
  //console.log(process.env.NODE_ENV);
  // // 拦截业务逻辑
  // // 进行请求配置的修改
  // // 如果本地有token就在头部携带
  // // 1. 获取用户信息对象
  // const { profile } = store.state.user
  // // 2. 判断是否有token
  // if (profile.token) {
  //   // 3. 设置token
  //   config.headers.Authorization = `Bearer ${profile.token}`
  // }
  // config.headers.Jerry = "Jerry";
  return config
}, err => {
  console.log("requestErrr",err);
  vue.$lhMessage("error","请求异常");
  return Promise.reject(err)
})

// res => res.data  取出data数据，将来调用接口的时候直接拿到的就是后台的数据
instance.interceptors.response.use(res =>{
  if(res.status!=200){
    return res.data;
  }else if(res.data.code==200){
    return res.data.data;
  }else{
    vue.$lhMessage("error",res.data.message);
    return Promise.reject(res.data.message);
  }
}, (err) => {
  //return Promise.reject(err);
  console.log("responseError",err);
  vue.$lhMessage("error","服务器启动中，请稍后再试");
  return Promise.reject(err);
})

// instance.post=function(url,data){
//   let request={
//     url:url,
//     data:data,
//     method:"post"
//   }
//   return instance(request);
// }

// instance.get=function(url,params){
//   console.log(params);
//   let request={
//     url:url,
//     params:params,
//     method:"get"
//   }
//   return instance(request);
// }

// 请求工具函数
export default instance;